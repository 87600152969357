<div class="modal-header">
  <h4 class="modal-title">Media manager</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="nav nav-tabs">
    <a class="nav-link" data-toggle="tab" [ngClass]="{active: tab === 'library'}" (click)="changeTab('library')">Library</a>
    <a class="nav-link" data-toggle="tab" [ngClass]="{active: tab === 'remote'}" (click)="changeTab('remote')">Remote media</a>
    <a class="nav-link" data-toggle="tab" [ngClass]="{active: tab === 'upload'}" (click)="changeTab('upload')">Upload media</a>
  </div>
  <div class="mt-3 tab-content">
    <div class="row" *ngIf="tab === 'library'">
      <div class="col">
        <div class="card mb-0">
          <div class="card-header">
            List of media:
          </div>
          <div class="card-body">
            <div class="media-list">
              <ul *ngIf="files.length > 0" class="media-list-inner">
                <li *ngFor="let media of files; let i = index">
                  <app-media-thumb
                    [isActive]="activeEditMedia?._id === media._id"
                    [media]="media"
                    (onSelect)="selectToEdit(media)"
                    (onRemove)="removeMedia(media, i)"
                  ></app-media-thumb>
                </li>
              </ul>
              <div class="text-center" *ngIf="files?.length === 0">
                <h5>There are no files. Please upload your first one</h5>
                <br>
                <button class="btn btn-secondary" (click)="changeTab('upload')">Go to the upload tab</button>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <ngb-pagination
              class="d-block mb-n3"
              [collectionSize]="totalMedia"
              [(page)]="page"
              [maxSize]="12"
              (pageChange)="loadLibrary()"
            ></ngb-pagination>
          </div>
        </div>

      </div>
      <div class="col-sm-3">
        <div class="card mb-0">
          <div class="card-header">
            Media details:
          </div>
          <div *ngIf="activeEditMedia" class="card-body">
            <form  (submit)="update(frm)" #frm="ngForm">
              <div class="form-group">
                <media-preview [media]="activeEditMedia"></media-preview>
              </div>
              <div class="form-group">
                <label>URL</label>
                <input type="text" class="form-control" [ngModel]="activeEditMedia.fileUrl" disabled name="url" />
              </div>
              <div class="form-group">
                <label>File name</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter media name"
                  [(ngModel)]="activeEditMedia.name"
                  required
                  #name="ngModel"
                  name="name"
                />
                <div *ngIf="name.errors && (name.dirty || name.touched || submitted)">
                  <p [hidden]="!name.errors.required" class="error">File name is required</p>
                </div>
              </div>
              <div class="form-group">
                <label>File description</label>
                <textarea
                  class="form-control"
                  placeholder="Enter description"
                  [(ngModel)]="activeEditMedia.description"
                  #desc="ngModel"
                  name="desc"
                ></textarea>
              </div>
              <button type="submit" class="btn btn-outline-dark pull-right">Update</button>
            </form>
          </div>
          <div *ngIf="!activeEditMedia" class="card-body text-center">
            <h5 class="card-title mb-0">Please select any media to edit information.</h5>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="tab === 'remote'">
      <form (submit)="submitRemoteMedia(remoteLinkForm)" #remoteLinkForm="ngForm">
        <div class="form-group">
          <label>URL</label>
          <input
            type="text"
            class="form-control"
            placeholder="Enter media URL name"
            required
            name="url"
            [(ngModel)]="remoteMediaForm.url"
            #remoteUrl="ngModel"
          />
          <div *ngIf="remoteUrl.errors && (remoteUrl.dirty || remoteUrl.touched || remoteFormSubmitted)">
            <p [hidden]="!remoteUrl.errors.required" class="error">File name is required</p>
          </div>
        </div>
        <div class="form-group">
          <label>Media name</label>
          <input
            type="text"
            class="form-control"
            placeholder="Enter media name"
            required
            name="name"
            [(ngModel)]="remoteMediaForm.name"
            #remoteName="ngModel"
          />
          <div *ngIf="remoteName.errors && (remoteName.dirty || remoteName.touched || remoteFormSubmitted)">
            <p [hidden]="!remoteName.errors.required" class="error">File name is required</p>
          </div>
            <!--  -->
        </div>
        <div class="form-group">
          <label>Media description</label>
          <input
            type="text"
            class="form-control"
            placeholder="Enter media description"
            required
            name="description"
            [(ngModel)]="remoteMediaForm.description"
            #remoteDescription="ngModel"
          />
          <div *ngIf="remoteDescription.errors && (remoteDescription.dirty || remoteDescription.touched || remoteFormSubmitted)">
            <p [hidden]="!remoteDescription.errors.required" class="error">File description is required</p>
          </div>
        </div>
        <button type="submit" class="btn btn-secondary">Submit</button>
      </form>
    </div>
    <div *ngIf="tab === 'upload'">
      <app-file-upload [options]="fileSelectOptions"></app-file-upload>
    </div>
    <!-- <div class="row" *ngIf="tab === 'upload'">
      <div class="col col-md-12" [hidden]="!imageBase64">
        <image-cropper
          [imageBase64]="imageBase64"
          [maintainAspectRatio]="false"
          format="png"
          (imageCropped)="imageCropped($event)"
        ></image-cropper>
  
        <hr />
        <button class="btn btn-primary pull-right" type="button" (click)="crop()">OK</button>
      </div>
      <div class="table-responsive" [hidden]="imageBase64">
        <table class="table">
          <thead>
            <tr>
              <th width="200"></th>
              <th>Name</th>
              <th>Description</th>
              <th>#</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let media of uploadedFiles">
              <td >
                <media-preview [media]="media"></media-preview>
              </td>
              <td>{{media.name}}</td>
              <td>{{media.description}}</td>
              <td>
                <span class="pointer" (click)="select(media)">Select</span>
              </td>
            </tr>
            <tr *ngFor="let file of filesQueue">
              <td>
                <img
                  class="img-fluid img-thumbnail media-gallery-item"
                  [src]="file.previewContent"
                  alt=""
                  *ngIf="isImage(file)"
                />
                <span class="d-none">TODO: change types to mimetype</span>
                <i class="ti-video-camera media-gallery-item" *ngIf="file.type === 'video'"></i>
                <i class="ti-file media-gallery-item" *ngIf="file.type === 'file'"></i>
  
                <div
                  class="progress-bar progress-bar-striped progress-bar-animated"
                  *ngIf="file.progress"
                  [ngStyle]="{width: file.progress + '%'}"
                ></div>
              </td>
              <td>{{file.file.name}}</td>
              <td></td>
              <td>
                <a *ngIf="isImage(file)" class="pointer" (click)="selectCrop(file)"><i class="fa fa-crop"></i></a>
                <a (click)="remove(file)"><i class="fa fa-times"></i></a>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="4">
                <app-file-upload [options]="fileSelectOptions"></app-file-upload>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div> -->
  </div>
</div>
<div class="modal-footer" *ngIf="tab === 'library'">
  <div *ngIf="activeEditMedia" class="h6 mr-5">SELECTED: {{ activeEditMedia.name }}</div>
  <button type="button" class="btn btn-primary" (click)="submit()" [disabled]="!activeEditMedia">Select</button>
</div>
